import Carousel from 'react-bootstrap/Carousel'

const CardCarousel = (props) => {
    const imagesItems = props.images.map((imgSrc) => {
        return (
            < Carousel.Item >
                <img
                    className="d-block w-100"
                    src={imgSrc}
                    alt="First slide"
                />
            </Carousel.Item >
        );
    });
    return (
        <div>
            <Carousel
                fade
                controls ={false}
                indicators={false}
            >
               {imagesItems}
            </Carousel>
        </div>
    );
}

export default CardCarousel;