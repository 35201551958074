import Card from 'react-bootstrap/Card'
import { useState } from 'react'
import CardCarousel from './CardCarousel'

const CardCategory = (props) => {
    const [show, setShow] = useState(true);
    const [maxHeightValue, setHeight] = useState(400);
    const handleClick = () => {
        setShow(!show);
        if (!show) {
            setHeight(400);
        }
        else { setHeight(400); }
    }
    const cardStyle = {
        width: '20rem',
        display: 'inline-block',
        margin: 30,
        verticalAlign:'strech'
    }
    const bodyStyle={
        minHeight:250,
    }
    const pStyle = {
        textAlign: "start",
        maxHeight: maxHeightValue,
        overflowY:'auto',
    };
    return (
        <div
            style={cardStyle}
            onClick={handleClick}
        >
            <Card
                bg={'Light'}
                text={props.title}
                className="mb-2"
                key={props.title}
            >
                <Card.Header>{props.title}</Card.Header>
                <Card.Body style={bodyStyle}>
                    <Card.Text style={pStyle}>
                        {props.info}
                    </Card.Text>
                        <div aria-expanded="false" ><CardCarousel images={props.images} /> </div>
                </Card.Body>
            </Card>
        </div>
    );
}
export default CardCategory;