import Carousel from 'react-bootstrap/Carousel'
import { mbs1, mbs2, mbs3, mbs4, mbs7, mbs8 } from './Photos';

const CarouselMarx = () => {
    const elmStyle = {
        maxWidth:'70%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '3%'
    };
    return (
        <div div style={elmStyle}>
            <Carousel 
            variant="dark" 
            controls={false}
            >
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={mbs1}
                        alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={mbs2}
                        alt="Second slide"
                    />

                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={mbs3}
                        alt="Third slide"
                    />

                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={mbs4}
                        alt="Third slide"
                    />

                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={mbs7}
                        alt="slide"
                    />

                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={mbs8}
                        alt="slide"
                    />

                </Carousel.Item>
            </Carousel>
        </div>
    );
}

export default CarouselMarx;