import ListGroupItem from 'react-bootstrap/esm/ListGroupItem';
import ListGroup from 'react-bootstrap/ListGroup'
import Large from './Twitter';

const InfoContact = () => {
    const divStyle = {
        margin: 20,
        padding: 10,
    }
    const pStyle = {
        textAlign: "start",
        maxHeight: 400,
        marginLeft: '10%',
        marginRight: '10%',
        marginBottom: 30,
    };
    return (
        <div style={divStyle}>
            <ListGroup
                variant="flush"
                style={pStyle}
            >
                <ListGroupItem><Large/></ListGroupItem>
                <ListGroupItem>Marx & Schiller sp. z o.o. sp. j.</ListGroupItem>
                <ListGroupItem>VAT: PL6772425607</ListGroupItem>
                <ListGroupItem>Ul. Tomasza Wojtasa 39 32-064 Rudawa POLAND</ListGroupItem>
                <ListGroupItem>hotline: <a href="tel:+48-12-357-20-25">+48 123572025</a></ListGroupItem>
            </ListGroup>
        </div>
    );
}
export default InfoContact;