import ListGroup from 'react-bootstrap/ListGroup'
import { GoPrimitiveDot } from 'react-icons/go'
const InfoProduction = () => {
    const divStyle = {
        margin: 20,
        padding: 10,
    }
    const pStyle = {
        textAlign: "start",
        maxHeight: 400,
        marginLeft: '10%',
        marginRight: '10%',
        marginBottom: 30,
    };
    const listStyle = {
        textAlign: "start",
        maxHeight: 400,
        marginLeft: '11%',
        marginRight: '10%',
        marginBottom: 30,
    }
    const title = 'Production Capabilities';
    const subTitleOne = 'state of the art steel manufacturing shop';
    const subTitleTwo = 'furniture and plastic forming shop';
    const subTitleThree = 'paint shop';
    const subTitleFour = 'electronic and electric assembly shop';
    const pOne = [
        'CNC fiber laser',
        'CNC mill',
        'CNC lathe',
        'CNC shear 3000mm',
        '3000mm 100t CNC 7 axis brake press',
        'eccentric and hydraulic presses',
        'line saws',
        'TIG, MIG, arc and spot welding',
    ];
    const pTwo = [
        'CNC 3800 x 2200mm mill',
        'panel and format saws',
        'CNC edgebander'
    ]
    const pThree = [
        'wet paint lines - low pressure wet painting, large object hydrodynamic painting',
        'powder coating line'
    ]
    const pointsOne = pOne.map((p) => { return <ListGroup.Item >{p}</ListGroup.Item> });
    const pointsTwo = pTwo.map((p) => { return <ListGroup.Item >{p}</ListGroup.Item> });
    const pointsThree = pThree.map((p) => { return <ListGroup.Item >{p}</ListGroup.Item> });
    return (
        <div style={divStyle} id={'production'}>
            <h3>{title}</h3>
            <br></br>
            <h5 style={pStyle}><GoPrimitiveDot /> {subTitleOne}:</h5>
            <ListGroup
                variant="flush"
                style={listStyle}
            >
                {pointsOne}
            </ListGroup>
            <h5 style={pStyle}><GoPrimitiveDot />{subTitleTwo}:</h5>
            <ListGroup
                variant="flush"
                style={listStyle}
            >
                {pointsTwo}
            </ListGroup>
            <h5 style={pStyle}><GoPrimitiveDot />{subTitleThree}:</h5>
            <ListGroup
                variant="flush"
                style={listStyle}
            >
                {pointsThree}
            </ListGroup>
            <h5 style={pStyle}><GoPrimitiveDot />{subTitleFour}</h5>
        </div>
    );
}
export default InfoProduction;