const InfoMission = () => {
    const divStyle = {
        margin: 20,
        marginBottom: 40,
        padding: 10,

    }
    const pStyle = {
        marginLeft: '10%',
        marginRight: '10%',
        marginBottom: 30,

    };
    const title = "Mission - What We Are About";
    const info = `We are a team of trained professionals dedicated to deliver the highest quality sale support solutions.\n
    Located in Kraków | Rudawa, Poland we are focused on the European Union \n
    market with years of experience in delivery and service on the „Old Continent”. \n
    We strive to provide innovative and complex products, systems and services \n
     helping our clients sell, advertise and stay close to the customer.`;
    return (
        <div style={divStyle}>
            <h3>{title}</h3>
            <p style={pStyle}>{info}</p>
        </div>
    );
}

export default InfoMission;