import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import InfoContact from './InfoContact'
import Image from 'react-bootstrap/esm/Image'
import mapa from './assets/map/map.png'
const Contact = () => {
    const divStyle = {
        margin: 20,
        padding: 10,
    }
    return (
        <div style={divStyle} id={'contact'}>
            <h3 style={divStyle}>Contact</h3>
            <Container>
                <Row>
                    <Col>
                        <InfoContact />
                    </Col>
                    
                    <Col>
                        <Col><a href="https://goo.gl/maps/LSnhitvFpmMJKdSP8"><Image fluid={true} src={mapa}/></a></Col>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Contact;