import Image from 'react-bootstrap/Image'
import qr from './assets/topLogoAndQr/qr.png'
const TopLogoQr = () => {
    const elmStyle = {
        marginLeft: "auto",
    marginRight: "auto",
    };
    const qrStyle = {
        width: '6%',
        margin: 20
    }
    return (
        <div style={elmStyle}>
            <div>
                <Image
                    src={qr}
                    fluid
                    style={qrStyle}
                />
            </div>
        </div>
    );
}

export default TopLogoQr;