import { Follow } from 'react-twitter-widgets'

const Large = () => {
  const options = { size: "large" }
  return (
    <div>
      <Follow username="MarxSchiller" options={options} />
    </div>
  );
}

export default Large;