import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import companyLogo from './assets/topLogoAndQr/logo.png'
import pl from './assets/navbar/poland.png'
import Image from 'react-bootstrap/esm/Image';
import { useState } from 'react';

const NavbarMarx = () => {
    const [eng,setEng] = useState(true)
    const handleClick = () => {
        setEng(false)
        if (!eng) {window.location.replace("http://marxschiller.com/");}
    }
    return (
        <Navbar bg="light" expand="lg" sticky="top">
            <Container>
                <Navbar.Brand href="#home" style={{alignSelf: 'center'}}>
                    <img
                        src= {companyLogo}
                        width="50%"
                        className="d-inline-block align-top"
                        alt="logo"
                        onClick={handleClick}
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="#solutions">Solutions</Nav.Link>
                        <Nav.Link href="#production">Production</Nav.Link>
                        <Nav.Link href="#contact">Contact</Nav.Link>
                        <Nav.Link ><Image src={pl}/></Nav.Link>
                        
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavbarMarx;