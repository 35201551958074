import CarouselMarx from './Carousel';
import InfoMission from './InfoMission';
import TopLogoQr from './TopLogoQr';
import InfoKeyAdv from './InfoKeyAdv';
import CardsCategory from './InfoCategoryCards';
import InfoProduction from './InfoProduction';
import FooterMarx from './Footer';
import Contact from './Contact';
import Solutions from './Solutions';
const Home = () => {
    return (
        <div>
            <CarouselMarx />
            <TopLogoQr/>
            <InfoMission />
            <Solutions />
            <InfoKeyAdv />
            <CardsCategory />
            <InfoProduction />
            <Contact />
            <FooterMarx />
        </div>
    );
}

export default Home;