import ListGroup from 'react-bootstrap/ListGroup'
const Solutions = () => {
    const divStyle = {
        margin: 20,
        marginBottom: 40,
        padding: 10,
    }
    const pStyle = {
        marginLeft: '30%',
        marginRight: '30%',
        marginBottom: 30,
    };
    const title = "Solutions:";
    const pnts = [
        "mobile sale solutions",
        "POS | POP",
        "store in store | trade fairs",
        "digital signage | e-POS",
        "IoT | process automation",
        "service"
    ];
    const points = pnts.map((p) => { return <ListGroup.Item >{p}</ListGroup.Item> });
    return (
        <div style={divStyle} >
            <h3>{title}</h3>
            <ListGroup
                variant="flush"
                style={pStyle}
            >
                {points}
                
            </ListGroup>
        </div>
    );
}

export default Solutions;