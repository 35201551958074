import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";

const FooterMarx = () => {

    return (
        <div>
            <MDBFooter color="blue" className="font-small pt-4 mt-4">
                <MDBRow>
                    <div className="footer-copyright text-center py-3">
                        <MDBContainer fluid>
                            &copy; {new Date().getFullYear()} Copyright: Marx & Schiller
                        </MDBContainer>
                    </div>
                </MDBRow>
            </MDBFooter>
        </div>
    );
}

export default FooterMarx;

