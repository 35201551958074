import ListGroup from 'react-bootstrap/ListGroup'
import { MdDone } from 'react-icons/md'
const infoKeyAdv = () => {
    const divStyle = {
        margin: 20,
        marginBottom: 40,
        padding: 10,

    }
    const pStyle = {
        marginLeft: '30%',
        marginRight: '30%',
        marginBottom: 30,

    };
    const title = "Key Advantages";
    const info = `All is produced in house on our production lines in Kraków Rudawa. This guarantees:`;
    const pnts = [
        "highest european quality",
        "short production times",
        "taylor made solutions",
    ];
    const points = pnts.map((p) => { return <ListGroup.Item ><MdDone/> {p}</ListGroup.Item> });
    return (
        <div style={divStyle} id="keyAdvantages">
            <h3>{title}</h3>
            <p style={pStyle}>{info}</p>
            <ListGroup
                variant="flush"
                style={pStyle}
            >
                {points}
                
            </ListGroup>
        </div>
    );
}

export default infoKeyAdv;