import CardCategory from "./InfoCategoryCard";
import * as photos from './Photos'
import Masonry from 'react-masonry-css'
const CardsCategory = () => {
    const titles = [
        {
            title: 'Mobile Sale Solutions',
            info: `Containers, booths, stands, carts and kiosks.
        We have developed our own line of mobile outdoor and indoor sales plaforms.`, images: [
                photos.mbs1,
                photos.mbs2,
                photos.mbs3,
                photos.mbs4,
                photos.mbs5,
                photos.mbs6,
                photos.mbs7,
                photos.mbs8,
                photos.mbs9,
                photos.mbs10,
                photos.mbs11]
        },
        {
            title: 'POS | POP Systems', info: `We are proud to offer quality services starting from the concept and design phase,
        through technical design and process implementation and ending with a serial production 
        of POS oriented products.`, images: [
                photos.pspp1,
                photos.pspp2,
                photos.pspp3,
                photos.pspp4,
                photos.pspp5,
                photos.pspp6,
                photos.pspp7,
                photos.pspp8,
                photos.pspp9,
                photos.pspp10]
        },
        {
            title: 'Digital Signage | e-POS', info: `We know information is the key to your sucess. 
        It is up to us to deliver this information fast and in a manner that is afficient for the bussiines client and end consumer. 
        This is where e-POS and digital signage systems save the day. 
        We provide taylor made products taht suit the exact needs of the customer. 
        Lcd and e-paper screens, digital kiosks, interactive product racks, 
        vending machines and information stands - please let us know how we can be of assistance.`, images: [
                photos.epos1,
                photos.epos2,
                photos.epos3,
                photos.epos4,
                photos.epos5,
                photos.epos6
            ]
        },
        {
            title: 'Store in Store', info: `If larger forms are involved we have you covered! We provide trade fair solutions, store in store, shopping mall stands, 
        cultural and information areas and much more. 
        We will custom design, engeneer and manufacture to your specification. 
        Our services also include on site service, instalation and logistics.`,
            images: [
                photos.stinst1,
                photos.stinst2,
                photos.stinst3,
                photos.stinst4]
        },
        {
            title: 'Trade Fair', info: `We design, produce, deliver and assemble tailor made trade fair stands. 
        Custom construction systems, furniture, electrical, sanitary, large format graphic displays and signs,
         flooring, multimedia instalations, audio systems and more!
        We also provide warehousing of dismantled stands until they are needed once more.`, images: [
                photos.fair1,
                photos.fair2,
                photos.fair3,
                photos.fair4,
                photos.fair5,
                photos.fair6,
                photos.fair7]
        },
        {
            title: 'IoT', info: `The ability to automate sale, information, service and interaction proceses in real time is tempting. 
        With us this temptation can be fullfilled!`, images: [
                photos.iot1,
                photos.iot2,
                photos.iot3,
                photos.iot4,
                photos.iot5]
        },
        {
            title: 'Process Automation', info: `We strive to provide quality solutions in automation of sales and information.
        Our services and products have helped hundreds reach their goals faster and with greater ease.`, images: [
                photos.praut1,
                photos.praut2,
                photos.praut3,
                photos.praut4,
                photos.praut5]
        },
        {
            title: 'Technology Integrator', info:
                `Our staff is capable of handling complex design and production tasks combining different manufacturing technologies.
         We combine metal, plastic and wood production with modern electronics and IT solutions.`, images: [photos.techin1]
        },
        {
            title: 'Service', info: `We offer instalation, product evaluation, warranty or post warranty service. 
        We have teams ready to assist you covering the EU territory and coresponding markets.`, images: [photos.srv1,]
        },
    ]
    const cards = titles.map((infos, index) => {
        return (
            <div><CardCategory
                key={index}
                title={infos.title}
                info={infos.info}
                images={infos.images} ></CardCategory></div>
        );
    });
    const breakpoints = {
        default: 4,
        1500:3,
        1100: 2,
        700:1
    }
    return (
        <div id="solutions">
        <Masonry
            breakpointCols={breakpoints}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column">
            {cards}
        </Masonry>
        </div>
    );

}

export default CardsCategory;