import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarMarx from './Navbar';
import Home from './Home';

function App() {
  return (
    <div className="App">
      <NavbarMarx />
      <div className="content">
        < Home />
      </div>
    </div>
  );
}

export default App;
